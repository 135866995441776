<template>
  <div>
    <b-modal
      cancel-variant="outline-secondary"
      :ok-title="okTitle"
      :cancel-title="cancelTitle"
      centered
      :title="title"
      :visible="visible"
      @ok="confirmFun"
      @close="cancelFun"
      @cancel="cancelFun"
      @hide="prevent"
      :okOnly="okOnly"
      :noCloseOnBackdrop="noCloseOnBackdrop"
      no-fade
    >
      <slot></slot>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, VBModal
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import common from "@/common";

export default {
  name: "Modal",
  components: {
    BModal,
    VBModal
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    okTitle: {type: String, default: common.getI18n('common.confirm')},
    cancelTitle: {type: String, default: common.getI18n('common.cancel')},
    title: {type: String},
    visible: {type: Boolean},
    confirmFun: {type: Function},
    cancelFun: {type: Function},
    okOnly: {type: Boolean, default: false},
    noCloseOnBackdrop: {type: Boolean, default: false},
  },
  methods: {
    prevent: function (e) {
      if (e.trigger === 'ok') {
        e.preventDefault()
      } else {
        this.cancelFun()
      }
    }
  }
}
</script>

<style scoped>

</style>
